<template>
  <div class="rf-multiple-input" :style="multipleInputStyle">
    <div class="multiple-input">
      <RFInput :label="label" :color="color" v-model="value" @key-enter="addValue"/>
      <FontAwesomeIcon v-if="value" class="add-value" color="#5CB85C" :icon="['fas', 'plus-circle']" @click="addValue"/>
    </div>
    <div class="values">
      <div class="value" v-for="(value, index) in values" :key="index">
        {{value}}
        <FontAwesomeIcon class="cursor-pointer ml-3" :icon="['fas', 'times']" @click="deleteValue(index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'

export default {
  name: 'RFMultipleInput',
  components: {
    RFInput,
  },
  props: {
    color: {
      type: String,
      default: '#24425B',
    },
    label: String,
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      values: [],
      value: null,
    }
  },
  computed: {
    multipleInputStyle() {
      return {
        '--color': this.color,
      }
    }
  },
  beforeMount() {
    if (this.list.length) {
      this.values = this.list
    }
  },
  methods: {
    addValue() {
      if (this.value) {
        this.values.push(this.value)
        this.value = null
      }
    },
    deleteValue(index) {
      this.values.splice(index, 1)
    }
  },
  watch: {
    values: {
      handler() {
        this.$emit('input', this.values)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-multiple-input {
  .multiple-input {
    display: flex;
    align-items: center;

    .add-value {
      cursor: pointer;
      position: relative;
      bottom: -10px;
      margin-left: 10px;
    }
  }
  .values {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .value {
      display: flex;
      align-items: center;
      @include font-style($montserrat-light, 'light', $font-10);
      background-color: var(--color);
      color: $white;
      padding: 0 10px;
      border: 1px solid $black;
      margin-right: 10px;
      border-radius: 10px;
      margin-top: 5px;
      white-space: nowrap;
    }
  }
}
</style>