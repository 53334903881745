<template>
  <div class="add-building">
    <h1 class="title">{{$t('New Building')}}</h1>
    <ValidationObserver ref="validator">
      <form class="add-building-form" @submit.prevent>
        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Name">
              <RFInput label="Name" v-model="building.buildingName"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Building Code">
              <RFInput label="Building Code" v-model="building.buildingCode"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Type">
              <RFSelect top-label="Type" v-model="building.resTypeId" @input="building.resTypeId = $event" :list="buildingTypeList"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Description">
              <RFInput label="Description" v-model="building.description"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Building Characteristics" v-model="editedAmenities" :list="characteristics" property="name"/>
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Address')}}</h4>

        <b-row>
          <b-col>
            <RFValidation rules="required" name="Country">
              <RFInput label="Country" v-model="building.addressCountry"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="City">
              <RFInput label="City" v-model="building.addressCity"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Zip Code">
              <RFInput label="Zip Code" v-model="building.addressZipCode" is-numeric/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Street">
              <RFInput label="Street" v-model="building.addressStreet"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Number">
              <RFInput label="Number" v-model="building.addressNumber" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFMultipleInput label="Nearest Universities" v-model="building.nearestUni"/>
          </b-col>
        </b-row>
        <b-row class="building-section">
          <b-col class="my-4">
            <h2 class="section-title header">{{$t('Building')}} {{$t('Primary Image')}}</h2>
          </b-col>
          <b-col cols="12">
            <AdminImageList :selected-image-index="selectedImageIndex" :multiple="false" :limit="1" :accept-videos="true" :validate-file-size-limit="true"
              @select="selectedImageIndex = $event" @images="images = $event" min-height="300px"/>
          </b-col>
          <b-col class="my-4">
            <p class="admin-aclaration">{{$t(`Images and videos max size is ${fileSizeLimit} MB (Max size recommended: ${fileSizeLimitRecommended} MB)`)}}</p>
          </b-col>
        </b-row>
        <b-row class="building-section">
          <b-col class="mb-4">
            <div class="header d-flex justify-content-between">
              <div class="section-title">{{$t('Building Infos')}}</div>
              <div class="add-info-label cursor-pointer" @click="addInfo">
                <FontAwesomeIcon :icon="['fas', 'plus-circle']" color="#5CB86C" /> {{$t('Add Info')}}
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="mb-4" >
            <AdminInfoList @infos="infos = $event" :add-info="addingInfo" :editable="true"/>
          </b-col>
        </b-row>
        <b-row class="justify-content-end">
          <b-col cols="auto">
            <RFButton class="mt-3" label="Save" background="#5CB86C" v-if="!loading" @on-click="save"/>
            <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFButton from '@/components/forms/RFButton'
import RFSelect from '@/components/forms/RFSelect'
import RFSelectItems from '@/components/forms/RFSelectItems'
import RFFile from '@/components/forms/RFFile'
import RFLoader from '@/components/forms/RFLoader'
import RFTextArea from '@/components/forms/RFTextArea'
import RFValidation from '@/components/forms/RFValidation'
import RFMultipleInputAutocomplete from '@/components/forms/RFMultipleInputAutocomplete'
import RFMultipleInput from '@/components/forms/RFMultipleInput'

import AdminImageList from '@/components/admin/AdminImageList'
import AdminInfoList from '@/components/admin/AdminInfoList'

import {extractIds} from '@/helpers/functions'

import {buildingService} from '@/services/admin'
import {buildingTypeService} from '@/services/admin'
import {mapState} from 'vuex'

export default {
  name: 'AddBuilding',
  components: {
    RFInput,
    RFButton,
    RFSelect,
    RFSelectItems,
    RFFile,
    RFLoader,
    RFTextArea,
    RFValidation,
    RFMultipleInputAutocomplete,
    RFMultipleInput,

    AdminImageList,
    AdminInfoList,
  },
  data() {
    return {
      building: {
        buildingName: null,
        description: null,
        resTypeId: null,
        addressStreet: null,
        addressNumber: null,
        addressZipCode: null,
        addressCity: null,
        addressCountry: null,
        nearestUni: [],
        characteristicIds: [],
        buildingCode: null,
      },

      editedAmenities: [],
      buildingTypeList: [],
      images: [],
      infos: [],
      selectedImageIndex: 0,
      loading: false,
      addingInfo: false,
      fileSizeLimit: 100, //In MB
      fileSizeLimitRecommended: 10, //In MB
    }
  },
  computed: {
    ...mapState({
      characteristics: state => state.adminCharacteristicStore.characteristics,
      //buildingTypes: state => state.adminBuildingTypeStore.buildingTypes,
    })
  },
  watch: {
    editedAmenities: {
      deep: true,
      handler() {
        this.building.characteristicIds = extractIds(this.editedAmenities)
      }
    },
  },
  beforeMount() {
    //this.buildingTypeList = this.buildingTypes.map(type => ({ value: type.id, label: type.name }))
    this.updateBuildingTypes()
  },
  methods: {
    async updateBuildingTypes() {
      this.buildingTypes = await buildingTypeService.getBuildingTypes()
      this.buildingTypeList = this.buildingTypes.map(type => ({ value: type.id, label: type.name }))

    },
    infoChange(info, index) {
      this.infos[index] = info
    },
    addInfo() {
      this.addingInfo = true

      window.setTimeout(() => {
        this.addingInfo = false
      }, 500)
    },
    deleteInfo(index) {
      this.infos.splice(index, 1)
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = true
        const { data: buildingId, status } = await buildingService.addBuilding(this.building)

        if (status === 200) {
          await this.addImages(buildingId)
          await this.addInfos(buildingId)

          this.$toasted.success(this.$i18n.t(`Building added successfully!`))
          this.$router.push({ name: 'building-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during add building`))
        }

        this.loading = false
      }
    },
    async addImages(buildingId) {
      this.images.forEach(async (image, index) => {
        const form = new FormData()

        form.append('file', image)
        form.append('isPrimary', this.selectedImageIndex === index)

        await buildingService.addResource(buildingId, form)
      })
    },
    async addInfos(buildingId) {
      this.infos.forEach(async (info) => {
        const { title, description, file } = info
        const form = new FormData()

        form.append('title', JSON.stringify(title))
        form.append('description', JSON.stringify(description))
        form.append('file', file)

        await buildingService.addInfo(buildingId, form)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-building {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .building-section {
    margin-top: 40px;

    .header {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
    .add-info-label {
      @include font-style($montserrat, 'semibold', $font-16);
      color: $success;
    }
  }
}
</style>
