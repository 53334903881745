<template>
  <div class="rf-select-items" ref="select-items" :style="selectStyle">
    <template v-if="selectedItems.length < list.length">
      <div class="label">
        <RFInput class="mt-0" :color="color" :value="label" @focusin="focusIn" @focusout="focusOut" readonly/>
        <FontAwesomeIcon class="caret-icon" :icon="['fas', 'caret-down']"/>
      </div>
      <div class="list" v-if="isOpen">
        <div class="item" v-for="(item, index) in filteredList" :key="`item-${index}`" @click="selectItem(index)">
          {{item}}
        </div>
      </div>
    </template>
    <div class="selected-items">
      <div class="label" v-if="selectedItems.length === list.length">{{label}}</div>
      <div class="selected-item" :style="{ backgroundColor: color }" :class="{ 'my-1': selectedItems.length < list.length }"
       v-for="(selectedItem, index) in selectedItems" :key="`selected-${index}`">
        {{selectedItem}}
        <FontAwesomeIcon class="cursor-pointer ml-1" :icon="['fas', 'times']" @click="removeItem(index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'

export default {
  name: 'RFSelectItems',
  components: {
    RFInput,
  },
  props: {
    list: Array,
    selected: Array,
    color: {
      type: String,
      default: '#24425B',
    },
    label: String,
  },
  data() {
    return {
      selectedItems: [],
      isOpen: false,
    }
  },
  computed: {
    filteredList() {
      return this.list.filter(item => !this.selectedItems.includes(item))
    },
    selectStyle() {
      return {
        '--color': this.color,
      }
    }
  },
  mounted() {
    this.selectedItems = this.selected && this.selected.length ? this.selected : []
  },
  methods: {
    selectItem(index) {
      this.selectedItems.push(this.filteredList[index])
    },
    removeItem(index) {
      this.selectedItems.splice(index, 1)
    },
    focusIn() {
      this.isOpen = true
    },
    focusOut() {
      window.setTimeout(() => {
        this.isOpen = false
      }, 200)
    }
  },
  watch: {
    selectedItems: {
      handler() {
        this.$emit('input', this.selectedItems)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rf-select-items {
  margin-top: 20px;
  position: relative;

  .selected-items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .label {
      @include font-style($montserrat-bold, 'bold', $font-14);
      color: var(--color);
      margin-right: 20px;
    }
    .selected-item {
      @include font-style($montserrat, 'medium', $font-12);
      border: 1px solid var(--color);
      border-radius: 10px;
      padding: 0 10px;
      margin-right: 5px;
      color: $white;
    }
  }
  .label {
    position: relative;

    .caret-icon {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }
  .list {
    position: absolute;
    background-color: $white;
    border: 1px solid var(--color);
    border-top: none;
    width: 100%;
    z-index: 10;
    max-height: 150px;
    overflow-y: auto;

    .item {
      padding: 5px 15px;
      cursor: pointer;

      &:hover {
        background-color: var(--color);
        color: $white;
      }
    }
  }
}
</style>